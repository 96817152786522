.fileover {
  @apply border border-blue-600;
}

.app-dialog-footer {
  @apply block absolute bottom-0 left-0 right-0 p-6;
}

.cdk-drag-placeholder {
  opacity: 0;
}

.customize-column.cdk-drag-preview {
  @apply bg-blue-200;
}
