/*
  Usage: class="btn btn-[size] btn-[type]"
  Size: xs, s, sm, md, lg, xl
  Type: primary, secondary-gray, secondary-color, destructive-primary, destructive-secondary-gray ...
*/

.btn {
  @apply font-medium rounded-sm inline-flex gap-2 justify-center items-center disabled:opacity-50 enabled:cursor-pointer;

  &-xs {
    @apply h-7 px-2;
  }

  &-sm {
    @apply h-8 px-3;
  }

  &-md {
    @apply h-9 px-4;
  }

  &-lg {
    @apply h-10 px-5;
  }

  &-xl {
    @apply h-11 px-6;
  }

  &-primary {
    @apply text-white bg-blue-600 enabled:hover:bg-blue-700 enabled:active:bg-blue-800;
  }

  &-secondary {
    @apply text-blue-600 border enabled:hover:bg-neutral-100  enabled:active:bg-neutral-100 enabled:active:border-blue-600 disabled:text-gray-500;
  }

  &-danger {
    @apply text-white bg-red-600 border-red-600 enabled:hover:bg-red-700 enabled:active:bg-red-800;
  }

  &-outline-primary {
    @apply text-blue-600 border border-blue-600 enabled:hover:bg-blue-100 disabled:bg-neutral-alpha-100A disabled:text-neutral-1000 disabled:border-transparent;
  }

  &-outline-secondary {
    @apply text-neutral-1000 border border-[#091E4229] bg-white enabled:hover:bg-neutral-100 enabled:active:bg-neutral-200 disabled:bg-neutral-alpha-100A disabled:border-transparent;
  }

  &-outline-danger {
    @apply text-red-600 border border-red-600 enabled:hover:bg-red-100 disabled:bg-neutral-alpha-100A disabled:text-neutral-1000 disabled:border-transparent;
  }

  &-ghost-primary {
    @apply text-primary-600  enabled:hover:bg-primary-600/10  disabled:text-neutral-1000;
  }

  &-ghost-secondary {
    @apply text-neutral-1000  enabled:hover:bg-neutral-100 enabled:active:bg-neutral-200;
  }
}
