@font-face {
  font-family: 'icomoon';
  src: url('../assets/fonts/Icon/icomoon.eot?ig2qaz');
  src:
    url('../assets/fonts/Icon/icomoon.eot?ig2qaz#iefix') format('embedded-opentype'),
    url('../assets/fonts/Icon/icomoon.ttf?ig2qaz') format('truetype'),
    url('../assets/fonts/Icon/icomoon.woff?ig2qaz') format('woff'),
    url('../assets/fonts/Icon/icomoon.svg?ig2qaz#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-wl-airplay:before {
  content: '\e900';
}
.icon-wl-airpods:before {
  content: '\e901';
}
.icon-wl-alarm:before {
  content: '\e902';
}
.icon-wl-alarm-check:before {
  content: '\e903';
}
.icon-wl-alarm-minus:before {
  content: '\e904';
}
.icon-wl-alarm-off:before {
  content: '\e905';
}
.icon-wl-alarm-plus:before {
  content: '\e906';
}
.icon-wl-align-bottom:before {
  content: '\e907';
}
.icon-wl-align-center:before {
  content: '\e908';
}
.icon-wl-align-horizontal-centers:before {
  content: '\e909';
}
.icon-wl-align-justify:before {
  content: '\e90a';
}
.icon-wl-align-left:before {
  content: '\e90b';
}
.icon-wl-align-left-1:before {
  content: '\e90c';
}
.icon-wl-align-right:before {
  content: '\e90d';
}
.icon-wl-align-right-1:before {
  content: '\e90e';
}
.icon-wl-align-top:before {
  content: '\e90f';
}
.icon-wl-align-vertical-centers:before {
  content: '\e910';
}
.icon-wl-app-log-in:before {
  content: '\e911';
}
.icon-wl-app-log-out:before {
  content: '\e912';
}
.icon-wl-archive:before {
  content: '\e913';
}
.icon-wl-arrow-align-bottom:before {
  content: '\e914';
}
.icon-wl-arrow-align-left:before {
  content: '\e915';
}
.icon-wl-arrow-align-right:before {
  content: '\e916';
}
.icon-wl-arrow-align-top:before {
  content: '\e917';
}
.icon-wl-arrow-alt-down:before {
  content: '\e918';
}
.icon-wl-arrow-alt-down-left:before {
  content: '\e919';
}
.icon-wl-arrow-alt-down-right:before {
  content: '\e91a';
}
.icon-wl-arrow-alt-left:before {
  content: '\e91b';
}
.icon-wl-arrow-alt-right:before {
  content: '\e91c';
}
.icon-wl-arrow-alt-up:before {
  content: '\e91d';
}
.icon-wl-arrow-alt-up-left:before {
  content: '\e91e';
}
.icon-wl-arrow-alt-up-right:before {
  content: '\e91f';
}
.icon-wl-arrow-chevron-circle-down:before {
  content: '\e920';
}
.icon-wl-arrow-chevron-circle-left:before {
  content: '\e921';
}
.icon-wl-arrow-chevron-circle-right:before {
  content: '\e922';
}
.icon-wl-arrow-chevron-circle-up:before {
  content: '\e923';
}
.icon-wl-arrow-chevron-square-down:before {
  content: '\e924';
}
.icon-wl-arrow-chevron-square-left:before {
  content: '\e925';
}
.icon-wl-arrow-chevron-square-right:before {
  content: '\e926';
}
.icon-wl-arrow-chevron-square-up:before {
  content: '\e927';
}
.icon-wl-arrow-circle-down:before {
  content: '\e928';
}
.icon-wl-arrow-circle-down-left:before {
  content: '\e929';
}
.icon-wl-arrow-circle-down-right:before {
  content: '\e92a';
}
.icon-wl-arrow-circle-left:before {
  content: '\e92b';
}
.icon-wl-arrow-circle-right:before {
  content: '\e92c';
}
.icon-wl-arrow-circle-up:before {
  content: '\e92d';
}
.icon-wl-arrow-circle-up-left:before {
  content: '\e92e';
}
.icon-wl-arrow-circle-up-right:before {
  content: '\e92f';
}
.icon-wl-arrow-corner-down-left:before {
  content: '\e930';
}
.icon-wl-arrow-corner-down-right:before {
  content: '\e931';
}
.icon-wl-arrow-corner-left-down:before {
  content: '\e932';
}
.icon-wl-arrow-corner-left-up:before {
  content: '\e933';
}
.icon-wl-arrow-corner-right-down:before {
  content: '\e934';
}
.icon-wl-arrow-corner-right-up:before {
  content: '\e935';
}
.icon-wl-arrow-corner-up-left:before {
  content: '\e936';
}
.icon-wl-arrow-corner-up-right:before {
  content: '\e937';
}
.icon-wl-arrow-down:before {
  content: '\e938';
}
.icon-wl-arrow-down-left:before {
  content: '\e939';
}
.icon-wl-arrow-down-right:before {
  content: '\e93a';
}
.icon-wl-arrow-drop-down:before {
  content: '\e93b';
}
.icon-wl-arrow-drop-left:before {
  content: '\e93c';
}
.icon-wl-arrow-drop-right:before {
  content: '\e93d';
}
.icon-wl-arrow-drop-up:before {
  content: '\e93e';
}
.icon-wl-arrow-left:before {
  content: '\e93f';
}
.icon-wl-arrow-right:before {
  content: '\e940';
}
.icon-wl-arrow-signal-down:before {
  content: '\e941';
}
.icon-wl-arrow-signal-left:before {
  content: '\e942';
}
.icon-wl-arrow-signal-right:before {
  content: '\e943';
}
.icon-wl-arrow-signal-up:before {
  content: '\e944';
}
.icon-wl-arrow-square-down:before {
  content: '\e945';
}
.icon-wl-arrow-square-down-left:before {
  content: '\e946';
}
.icon-wl-arrow-square-down-right:before {
  content: '\e947';
}
.icon-wl-arrow-square-left:before {
  content: '\e948';
}
.icon-wl-arrow-square-right:before {
  content: '\e949';
}
.icon-wl-arrow-square-up:before {
  content: '\e94a';
}
.icon-wl-arrow-square-up-left:before {
  content: '\e94b';
}
.icon-wl-arrow-square-up-right:before {
  content: '\e94c';
}
.icon-wl-arrow-up:before {
  content: '\e94d';
}
.icon-wl-arrow-up-left:before {
  content: '\e94e';
}
.icon-wl-arrow-up-right:before {
  content: '\e94f';
}
.icon-wl-asterisk:before {
  content: '\e950';
}
.icon-wl-barcode:before {
  content: '\e951';
}
.icon-wl-bar-line-chart:before {
  content: '\e952';
}
.icon-wl-basket:before {
  content: '\e953';
}
.icon-wl-battery-charge:before {
  content: '\e954';
}
.icon-wl-battery-empty:before {
  content: '\e955';
}
.icon-wl-battery-full:before {
  content: '\e956';
}
.icon-wl-battery-good:before {
  content: '\e957';
}
.icon-wl-battery-low:before {
  content: '\e958';
}
.icon-wl-bezier-curve:before {
  content: '\e959';
}
.icon-wl-bitcoin:before {
  content: '\e95a';
}
.icon-wl-bitcoin-circle:before {
  content: '\e95b';
}
.icon-wl-blister-pack:before {
  content: '\e95c';
}
.icon-wl-blueprint:before {
  content: '\e95d';
}
.icon-wl-bluetooth:before {
  content: '\e95e';
}
.icon-wl-bluetooth-connect:before {
  content: '\e95f';
}
.icon-wl-bluetooth-off:before {
  content: '\e960';
}
.icon-wl-bluetooth-signal:before {
  content: '\e961';
}
.icon-wl-bold:before {
  content: '\e962';
}
.icon-wl-bolt:before {
  content: '\e963';
}
.icon-wl-bookmark:before {
  content: '\e964';
}
.icon-wl-bookmark-check:before {
  content: '\e965';
}
.icon-wl-bookmark-close:before {
  content: '\e966';
}
.icon-wl-bookmark-minus:before {
  content: '\e967';
}
.icon-wl-bookmark-plus:before {
  content: '\e968';
}
.icon-wl-border-all:before {
  content: '\e969';
}
.icon-wl-border-bottom:before {
  content: '\e96a';
}
.icon-wl-border-clear:before {
  content: '\e96b';
}
.icon-wl-border-horizontal:before {
  content: '\e96c';
}
.icon-wl-border-inner:before {
  content: '\e96d';
}
.icon-wl-border-left:before {
  content: '\e96e';
}
.icon-wl-border-outer:before {
  content: '\e96f';
}
.icon-wl-border-radius:before {
  content: '\e970';
}
.icon-wl-border-right:before {
  content: '\e971';
}
.icon-wl-border-top:before {
  content: '\e972';
}
.icon-wl-border-vertical:before {
  content: '\e973';
}
.icon-wl-brush:before {
  content: '\e974';
}
.icon-wl-building:before {
  content: '\e975';
}
.icon-wl-building-house:before {
  content: '\e976';
}
.icon-wl-buildings:before {
  content: '\e977';
}
.icon-wl-bullet-list:before {
  content: '\e978';
}
.icon-wl-calendar:before {
  content: '\e979';
}
.icon-wl-calendar-alt:before {
  content: '\e97a';
}
.icon-wl-calendar-check:before {
  content: '\e97b';
}
.icon-wl-calendar-close:before {
  content: '\e97c';
}
.icon-wl-calendar-down:before {
  content: '\e97d';
}
.icon-wl-calendar-left:before {
  content: '\e97e';
}
.icon-wl-calendar-minus:before {
  content: '\e97f';
}
.icon-wl-calendar-plus:before {
  content: '\e980';
}
.icon-wl-calendar-right:before {
  content: '\e981';
}
.icon-wl-calendar-up:before {
  content: '\e982';
}
.icon-wl-camera:before {
  content: '\e983';
}
.icon-wl-camera-off:before {
  content: '\e984';
}
.icon-wl-camera-plus:before {
  content: '\e985';
}
.icon-wl-card:before {
  content: '\e986';
}
.icon-wl-cart:before {
  content: '\e987';
}
.icon-wl-cart-alt:before {
  content: '\e988';
}
.icon-wl-cast:before {
  content: '\e989';
}
.icon-wl-check:before {
  content: '\e98a';
}
.icon-wl-check-circle:before {
  content: '\e98b';
}
.icon-wl-check-list:before {
  content: '\e98c';
}
.icon-wl-check-square:before {
  content: '\e98d';
}
.icon-wl-chevron-down:before {
  content: '\e98e';
}
.icon-wl-chevron-left:before {
  content: '\e98f';
}
.icon-wl-chevron-right:before {
  content: '\e990';
}
.icon-wl-chevrons-down:before {
  content: '\e991';
}
.icon-wl-chevrons-left:before {
  content: '\e992';
}
.icon-wl-chevrons-right:before {
  content: '\e993';
}
.icon-wl-chevrons-up:before {
  content: '\e994';
}
.icon-wl-chevron-up:before {
  content: '\e995';
}
.icon-wl-church:before {
  content: '\e996';
}
.icon-wl-circle:before {
  content: '\e997';
}
.icon-wl-clapperboard:before {
  content: '\e998';
}
.icon-wl-clipboard:before {
  content: '\e999';
}
.icon-wl-clipboard-alt-check:before {
  content: '\e99a';
}
.icon-wl-clipboard-alt-close:before {
  content: '\e99b';
}
.icon-wl-clipboard-alt-download:before {
  content: '\e99c';
}
.icon-wl-clipboard-alt-minus:before {
  content: '\e99d';
}
.icon-wl-clipboard-alt-plus:before {
  content: '\e99e';
}
.icon-wl-clipboard-alt-question:before {
  content: '\e99f';
}
.icon-wl-clipboard-alt-search:before {
  content: '\e9a0';
}
.icon-wl-clipboard-alt-upload:before {
  content: '\e9a1';
}
.icon-wl-clipboard-check:before {
  content: '\e9a2';
}
.icon-wl-clipboard-close:before {
  content: '\e9a3';
}
.icon-wl-clipboard-download:before {
  content: '\e9a4';
}
.icon-wl-clipboard-minus:before {
  content: '\e9a5';
}
.icon-wl-clipboard-plus:before {
  content: '\e9a6';
}
.icon-wl-clipboard-question:before {
  content: '\e9a7';
}
.icon-wl-clipboard-search:before {
  content: '\e9a8';
}
.icon-wl-clipboard-upload:before {
  content: '\e9a9';
}
.icon-wl-clock:before {
  content: '\e9aa';
}
.icon-wl-clock-backward:before {
  content: '\e9ab';
}
.icon-wl-clock-check:before {
  content: '\e9ac';
}
.icon-wl-clock-forward:before {
  content: '\e9ad';
}
.icon-wl-clock-minus:before {
  content: '\e9ae';
}
.icon-wl-clock-off:before {
  content: '\e9af';
}
.icon-wl-clock-plus:before {
  content: '\e9b0';
}
.icon-wl-close:before {
  content: '\e9b1';
}
.icon-wl-close-circle:before {
  content: '\e9b2';
}
.icon-wl-close-square:before {
  content: '\e9b3';
}
.icon-wl-closet:before {
  content: '\e9b4';
}
.icon-wl-cloud:before {
  content: '\e9b5';
}
.icon-wl-cloud-mist:before {
  content: '\e9b6';
}
.icon-wl-cloud-rainy:before {
  content: '\e9b7';
}
.icon-wl-cloud-snow:before {
  content: '\e9b8';
}
.icon-wl-cloud-thunder:before {
  content: '\e9b9';
}
.icon-wl-coin:before {
  content: '\e9ba';
}
.icon-wl-coin-stack:before {
  content: '\e9bb';
}
.icon-wl-collapse:before {
  content: '\e9bc';
}
.icon-wl-collapse-1:before {
  content: '\e9bd';
}
.icon-wl-collapse-alt:before {
  content: '\e9be';
}
.icon-wl-collapse-alt-1:before {
  content: '\e9bf';
}
.icon-wl-colors:before {
  content: '\e9c0';
}
.icon-wl-column-view-double:before {
  content: '\e9c1';
}
.icon-wl-column-view-triple:before {
  content: '\e9c2';
}
.icon-wl-column-view-vertical:before {
  content: '\e9c3';
}
.icon-wl-command:before {
  content: '\e9c4';
}
.icon-wl-config:before {
  content: '\e9c5';
}
.icon-wl-contrast:before {
  content: '\e9c6';
}
.icon-wl-credit-card:before {
  content: '\e9c7';
}
.icon-wl-crop:before {
  content: '\e9c8';
}
.icon-wl-cube:before {
  content: '\e9c9';
}
.icon-wl-currency-exchange:before {
  content: '\e9ca';
}
.icon-wl-cursor:before {
  content: '\e9cb';
}
.icon-wl-cursor-alt:before {
  content: '\e9cc';
}
.icon-wl-cut:before {
  content: '\e9cd';
}
.icon-wl-cut-alt:before {
  content: '\e9ce';
}
.icon-wl-cyclone:before {
  content: '\e9cf';
}
.icon-wl-cylinder:before {
  content: '\e9d0';
}
.icon-wl-delete:before {
  content: '\e9d1';
}
.icon-wl-disc:before {
  content: '\e9d2';
}
.icon-wl-discount:before {
  content: '\e9d3';
}
.icon-wl-discount-alt:before {
  content: '\e9d4';
}
.icon-wl-distribute-horizontal-spacing:before {
  content: '\e9d5';
}
.icon-wl-distribute-vertical-spacing:before {
  content: '\e9d6';
}
.icon-wl-dock-bottom:before {
  content: '\e9d7';
}
.icon-wl-dock-left:before {
  content: '\e9d8';
}
.icon-wl-dock-right:before {
  content: '\e9d9';
}
.icon-wl-dock-top:before {
  content: '\e9da';
}
.icon-wl-dollar:before {
  content: '\e9db';
}
.icon-wl-dollar-circle:before {
  content: '\e9dc';
}
.icon-wl-donut-chart:before {
  content: '\e9dd';
}
.icon-wl-door-open:before {
  content: '\e9de';
}
.icon-wl-dots-grid:before {
  content: '\e9df';
}
.icon-wl-dots-horizontal:before {
  content: '\e9e0';
}
.icon-wl-dots-vertical:before {
  content: '\e9e1';
}
.icon-wl-download:before {
  content: '\e9e2';
}
.icon-wl-download-cloud:before {
  content: '\e9e3';
}
.icon-wl-drag-handle:before {
  content: '\e9e4';
}
.icon-wl-drag-indicator:before {
  content: '\e9e5';
}
.icon-wl-droplet:before {
  content: '\e9e6';
}
.icon-wl-droplets:before {
  content: '\e9e7';
}
.icon-wl-duplicate:before {
  content: '\e9e8';
}
.icon-wl-edit:before {
  content: '\e9e9';
}
.icon-wl-edit-form:before {
  content: '\e9ea';
}
.icon-wl-emoji-angry:before {
  content: '\e9eb';
}
.icon-wl-emoji-confuse:before {
  content: '\e9ec';
}
.icon-wl-emoji-frown:before {
  content: '\e9ed';
}
.icon-wl-emoji-happy:before {
  content: '\e9ee';
}
.icon-wl-emoji-laugh:before {
  content: '\e9ef';
}
.icon-wl-emoji-meh:before {
  content: '\e9f0';
}
.icon-wl-emoji-neutral:before {
  content: '\e9f1';
}
.icon-wl-emoji-sad:before {
  content: '\e9f2';
}
.icon-wl-emoji-smile:before {
  content: '\e9f3';
}
.icon-wl-emoji-wink:before {
  content: '\e9f4';
}
.icon-wl-eraser:before {
  content: '\e9f5';
}
.icon-wl-error-circle:before {
  content: '\e9f6';
}
.icon-wl-error-square:before {
  content: '\e9f7';
}
.icon-wl-euro:before {
  content: '\e9f8';
}
.icon-wl-euro-circle:before {
  content: '\e9f9';
}
.icon-wl-event:before {
  content: '\e9fa';
}
.icon-wl-event-check:before {
  content: '\e9fb';
}
.icon-wl-event-close:before {
  content: '\e9fc';
}
.icon-wl-event-down:before {
  content: '\e9fd';
}
.icon-wl-event-left:before {
  content: '\e9fe';
}
.icon-wl-event-minus:before {
  content: '\e9ff';
}
.icon-wl-event-plus:before {
  content: '\ea00';
}
.icon-wl-event-right:before {
  content: '\ea01';
}
.icon-wl-event-up:before {
  content: '\ea02';
}
.icon-wl-expand:before {
  content: '\ea03';
}
.icon-wl-expand-1:before {
  content: '\ea04';
}
.icon-wl-expand-alt:before {
  content: '\ea05';
}
.icon-wl-expand-alt-1:before {
  content: '\ea06';
}
.icon-wl-external:before {
  content: '\ea07';
}
.icon-wl-eye:before {
  content: '\ea08';
}
.icon-wl-eye-drop:before {
  content: '\ea09';
}
.icon-wl-eye-off:before {
  content: '\ea0a';
}
.icon-wl-face-id:before {
  content: '\ea0b';
}
.icon-wl-factory:before {
  content: '\ea0c';
}
.icon-wl-fast-backward:before {
  content: '\ea0d';
}
.icon-wl-fast-forward:before {
  content: '\ea0e';
}
.icon-wl-file:before {
  content: '\ea0f';
}
.icon-wl-file-alt:before {
  content: '\ea10';
}
.icon-wl-file-blank:before {
  content: '\ea11';
}
.icon-wl-file-blank-check:before {
  content: '\ea12';
}
.icon-wl-file-blank-close:before {
  content: '\ea13';
}
.icon-wl-file-blank-download:before {
  content: '\ea14';
}
.icon-wl-file-blank-lock:before {
  content: '\ea15';
}
.icon-wl-file-blank-minus:before {
  content: '\ea16';
}
.icon-wl-file-blank-plus:before {
  content: '\ea17';
}
.icon-wl-file-blank-question:before {
  content: '\ea18';
}
.icon-wl-file-blank-search:before {
  content: '\ea19';
}
.icon-wl-file-blank-shield:before {
  content: '\ea1a';
}
.icon-wl-file-blank-upload:before {
  content: '\ea1b';
}
.icon-wl-file-check:before {
  content: '\ea1c';
}
.icon-wl-file-close:before {
  content: '\ea1d';
}
.icon-wl-file-download:before {
  content: '\ea1e';
}
.icon-wl-file-lock:before {
  content: '\ea1f';
}
.icon-wl-file-minus:before {
  content: '\ea20';
}
.icon-wl-file-plus:before {
  content: '\ea21';
}
.icon-wl-file-question:before {
  content: '\ea22';
}
.icon-wl-file-search:before {
  content: '\ea23';
}
.icon-wl-file-shield:before {
  content: '\ea24';
}
.icon-wl-file-upload:before {
  content: '\ea25';
}
.icon-wl-film:before {
  content: '\ea26';
}
.icon-wl-filters-funnel:before {
  content: '\ea27';
}
.icon-wl-filters-line:before {
  content: '\ea28';
}
.icon-wl-fire-extinguisher:before {
  content: '\ea29';
}
.icon-wl-fire-hydrant:before {
  content: '\ea2a';
}
.icon-wl-flash:before {
  content: '\ea2b';
}
.icon-wl-flash-off:before {
  content: '\ea2c';
}
.icon-wl-flood:before {
  content: '\ea2d';
}
.icon-wl-folder:before {
  content: '\ea2e';
}
.icon-wl-folder-alt-check:before {
  content: '\ea2f';
}
.icon-wl-folder-alt-close:before {
  content: '\ea30';
}
.icon-wl-folder-alt-download:before {
  content: '\ea31';
}
.icon-wl-folder-alt-lock:before {
  content: '\ea32';
}
.icon-wl-folder-alt-minus:before {
  content: '\ea33';
}
.icon-wl-folder-alt-plus:before {
  content: '\ea34';
}
.icon-wl-folder-alt-question:before {
  content: '\ea35';
}
.icon-wl-folder-alt-search:before {
  content: '\ea36';
}
.icon-wl-folder-alt-shield:before {
  content: '\ea37';
}
.icon-wl-folder-alt-upload:before {
  content: '\ea38';
}
.icon-wl-folder-check:before {
  content: '\ea39';
}
.icon-wl-folder-close:before {
  content: '\ea3a';
}
.icon-wl-folder-download:before {
  content: '\ea3b';
}
.icon-wl-folder-lock:before {
  content: '\ea3c';
}
.icon-wl-folder-minus:before {
  content: '\ea3d';
}
.icon-wl-folder-open:before {
  content: '\ea3e';
}
.icon-wl-folder-plus:before {
  content: '\ea3f';
}
.icon-wl-folder-question:before {
  content: '\ea40';
}
.icon-wl-folder-search:before {
  content: '\ea41';
}
.icon-wl-folder-shield:before {
  content: '\ea42';
}
.icon-wl-folder-upload:before {
  content: '\ea43';
}
.icon-wl-format-paint:before {
  content: '\ea44';
}
.icon-wl-format-paint-alt:before {
  content: '\ea45';
}
.icon-wl-front-door:before {
  content: '\ea46';
}
.icon-wl-gaming-pad:before {
  content: '\ea47';
}
.icon-wl-gaming-pad-alt:before {
  content: '\ea48';
}
.icon-wl-garage:before {
  content: '\ea49';
}
.icon-wl-gift:before {
  content: '\ea4a';
}
.icon-wl-gift-alt:before {
  content: '\ea4b';
}
.icon-wl-graduation:before {
  content: '\ea4c';
}
.icon-wl-grid:before {
  content: '\ea4d';
}
.icon-wl-group:before {
  content: '\ea4e';
}
.icon-wl-hand:before {
  content: '\ea4f';
}
.icon-wl-hard-drive:before {
  content: '\ea50';
}
.icon-wl-heading:before {
  content: '\ea51';
}
.icon-wl-headphone:before {
  content: '\ea52';
}
.icon-wl-heart:before {
  content: '\ea53';
}
.icon-wl-heat:before {
  content: '\ea54';
}
.icon-wl-heat-off:before {
  content: '\ea55';
}
.icon-wl-help-circle:before {
  content: '\ea56';
}
.icon-wl-help-square:before {
  content: '\ea57';
}
.icon-wl-hexagon:before {
  content: '\ea58';
}
.icon-wl-hexagon-alt:before {
  content: '\ea59';
}
.icon-wl-home:before {
  content: '\ea5a';
}
.icon-wl-home-alt:before {
  content: '\ea5b';
}
.icon-wl-horizontal-bar-chart:before {
  content: '\ea5c';
}
.icon-wl-horizontal-bar-chart-block:before {
  content: '\ea5d';
}
.icon-wl-horizontal-bar-chart-block-decrease:before {
  content: '\ea5e';
}
.icon-wl-horizontal-bar-chart-block-increase:before {
  content: '\ea5f';
}
.icon-wl-horizontal-bar-chart-block-line:before {
  content: '\ea60';
}
.icon-wl-horizontal-bar-chart-block-line-decrease:before {
  content: '\ea61';
}
.icon-wl-horizontal-bar-chart-block-line-increase:before {
  content: '\ea62';
}
.icon-wl-horizontal-bar-chart-circle:before {
  content: '\ea63';
}
.icon-wl-horizontal-bar-chart-circle-decrease:before {
  content: '\ea64';
}
.icon-wl-horizontal-bar-chart-circle-increase:before {
  content: '\ea65';
}
.icon-wl-horizontal-bar-chart-decrease:before {
  content: '\ea66';
}
.icon-wl-horizontal-bar-chart-increase:before {
  content: '\ea67';
}
.icon-wl-horizontal-bar-chart-line:before {
  content: '\ea68';
}
.icon-wl-horizontal-bar-chart-line-decrease:before {
  content: '\ea69';
}
.icon-wl-horizontal-bar-chart-line-increase:before {
  content: '\ea6a';
}
.icon-wl-horizontal-bar-chart-square:before {
  content: '\ea6b';
}
.icon-wl-horizontal-bar-chart-square-decrease:before {
  content: '\ea6c';
}
.icon-wl-horizontal-bar-chart-square-increase:before {
  content: '\ea6d';
}
.icon-wl-hourglass:before {
  content: '\ea6e';
}
.icon-wl-house-circle:before {
  content: '\ea6f';
}
.icon-wl-house-heart:before {
  content: '\ea70';
}
.icon-wl-house-smile:before {
  content: '\ea71';
}
.icon-wl-hurricane:before {
  content: '\ea72';
}
.icon-wl-icon-grid:before {
  content: '\ea73';
}
.icon-wl-id-card:before {
  content: '\ea74';
}
.icon-wl-image-indent-left:before {
  content: '\ea75';
}
.icon-wl-image-indent-right:before {
  content: '\ea76';
}
.icon-wl-inbox:before {
  content: '\ea77';
}
.icon-wl-info-circle:before {
  content: '\ea78';
}
.icon-wl-info-square:before {
  content: '\ea79';
}
.icon-wl-italic:before {
  content: '\ea7a';
}
.icon-wl-key:before {
  content: '\ea7b';
}
.icon-wl-keyboard:before {
  content: '\ea7c';
}
.icon-wl-key-off:before {
  content: '\ea7d';
}
.icon-wl-landslide:before {
  content: '\ea7e';
}
.icon-wl-laptop:before {
  content: '\ea7f';
}
.icon-wl-layers:before {
  content: '\ea80';
}
.icon-wl-layout:before {
  content: '\ea81';
}
.icon-wl-layout-alt:before {
  content: '\ea82';
}
.icon-wl-left-indent:before {
  content: '\ea83';
}
.icon-wl-lens:before {
  content: '\ea84';
}
.icon-wl-letter-spacing:before {
  content: '\ea85';
}
.icon-wl-lightbulb:before {
  content: '\ea86';
}
.icon-wl-line-chart-cicrle-up:before {
  content: '\ea87';
}
.icon-wl-line-chart-circle-down:before {
  content: '\ea88';
}
.icon-wl-line-chart-down:before {
  content: '\ea89';
}
.icon-wl-line-chart-square-down:before {
  content: '\ea8a';
}
.icon-wl-line-chart-square-up:before {
  content: '\ea8b';
}
.icon-wl-line-chart-trending-circle-down:before {
  content: '\ea8c';
}
.icon-wl-line-chart-trending-circle-up:before {
  content: '\ea8d';
}
.icon-wl-line-chart-trending-down:before {
  content: '\ea8e';
}
.icon-wl-line-chart-trending-square-down:before {
  content: '\ea8f';
}
.icon-wl-line-chart-trending-square-up:before {
  content: '\ea90';
}
.icon-wl-line-chart-trending-up:before {
  content: '\ea91';
}
.icon-wl-line-chart-up:before {
  content: '\ea92';
}
.icon-wl-line-height:before {
  content: '\ea93';
}
.icon-wl-link:before {
  content: '\ea94';
}
.icon-wl-link-broken:before {
  content: '\ea95';
}
.icon-wl-link-horizontal:before {
  content: '\ea96';
}
.icon-wl-lira:before {
  content: '\ea97';
}
.icon-wl-lira-circle:before {
  content: '\ea98';
}
.icon-wl-loading:before {
  content: '\ea99';
}
.icon-wl-location:before {
  content: '\ea9a';
}
.icon-wl-location-alt:before {
  content: '\ea9b';
}
.icon-wl-lock-alt:before {
  content: '\ea9c';
}
.icon-wl-lock-alt-open:before {
  content: '\ea9d';
}
.icon-wl-lock-keyhole-circle:before {
  content: '\ea9e';
}
.icon-wl-lock-keyhole-square:before {
  content: '\ea9f';
}
.icon-wl-log-in:before {
  content: '\eaa0';
}
.icon-wl-log-out:before {
  content: '\eaa1';
}
.icon-wl-magic-wand:before {
  content: '\eaa2';
}
.icon-wl-mail:before {
  content: '\eaa3';
}
.icon-wl-mail-open:before {
  content: '\eaa4';
}
.icon-wl-master-card:before {
  content: '\eaa5';
}
.icon-wl-medical:before {
  content: '\eaa6';
}
.icon-wl-medical-kit:before {
  content: '\eaa7';
}
.icon-wl-medicine-bottle:before {
  content: '\eaa8';
}
.icon-wl-medicines:before {
  content: '\eaa9';
}
.icon-wl-memory-card:before {
  content: '\eaaa';
}
.icon-wl-menu:before {
  content: '\eaab';
}
.icon-wl-menu-line:before {
  content: '\eaac';
}
.icon-wl-message-circle:before {
  content: '\eaad';
}
.icon-wl-message-circle-alert:before {
  content: '\eaae';
}
.icon-wl-message-circle-chat:before {
  content: '\eaaf';
}
.icon-wl-message-circle-chat-alt:before {
  content: '\eab0';
}
.icon-wl-message-circle-check:before {
  content: '\eab1';
}
.icon-wl-message-circle-close:before {
  content: '\eab2';
}
.icon-wl-message-circle-dots:before {
  content: '\eab3';
}
.icon-wl-message-circle-info:before {
  content: '\eab4';
}
.icon-wl-message-circle-notification:before {
  content: '\eab5';
}
.icon-wl-message-circle-plus:before {
  content: '\eab6';
}
.icon-wl-message-circle-question:before {
  content: '\eab7';
}
.icon-wl-message-circle-text:before {
  content: '\eab8';
}
.icon-wl-message-square:before {
  content: '\eab9';
}
.icon-wl-message-square-alert:before {
  content: '\eaba';
}
.icon-wl-message-square-chat:before {
  content: '\eabb';
}
.icon-wl-message-square-chat-alt:before {
  content: '\eabc';
}
.icon-wl-message-square-check:before {
  content: '\eabd';
}
.icon-wl-message-square-close:before {
  content: '\eabe';
}
.icon-wl-message-square-dots:before {
  content: '\eabf';
}
.icon-wl-message-square-info:before {
  content: '\eac0';
}
.icon-wl-message-square-notification:before {
  content: '\eac1';
}
.icon-wl-message-square-plus:before {
  content: '\eac2';
}
.icon-wl-message-square-question:before {
  content: '\eac3';
}
.icon-wl-message-square-text:before {
  content: '\eac4';
}
.icon-wl-microphone:before {
  content: '\eac5';
}
.icon-wl-microphone-off:before {
  content: '\eac6';
}
.icon-wl-minus:before {
  content: '\eac7';
}
.icon-wl-minus-circle:before {
  content: '\eac8';
}
.icon-wl-minus-square:before {
  content: '\eac9';
}
.icon-wl-money:before {
  content: '\eaca';
}
.icon-wl-monitor:before {
  content: '\eacb';
}
.icon-wl-moon:before {
  content: '\eacc';
}
.icon-wl-moon-cloud:before {
  content: '\eacd';
}
.icon-wl-moon-star:before {
  content: '\eace';
}
.icon-wl-mouse:before {
  content: '\eacf';
}
.icon-wl-move:before {
  content: '\ead0';
}
.icon-wl-move-to-inbox:before {
  content: '\ead1';
}
.icon-wl-museum:before {
  content: '\ead2';
}
.icon-wl-museum-alt:before {
  content: '\ead3';
}
.icon-wl-music:before {
  content: '\ead4';
}
.icon-wl-music-note:before {
  content: '\ead5';
}
.icon-wl-music-note-off:before {
  content: '\ead6';
}
.icon-wl-music-off:before {
  content: '\ead7';
}
.icon-wl-note:before {
  content: '\ead8';
}
.icon-wl-note-alt:before {
  content: '\ead9';
}
.icon-wl-note-blank:before {
  content: '\eada';
}
.icon-wl-note-blank-check:before {
  content: '\eadb';
}
.icon-wl-note-blank-close:before {
  content: '\eadc';
}
.icon-wl-note-blank-download:before {
  content: '\eadd';
}
.icon-wl-note-blank-minus:before {
  content: '\eade';
}
.icon-wl-note-blank-plus:before {
  content: '\eadf';
}
.icon-wl-note-blank-question:before {
  content: '\eae0';
}
.icon-wl-note-blank-search:before {
  content: '\eae1';
}
.icon-wl-note-blank-upload:before {
  content: '\eae2';
}
.icon-wl-note-check:before {
  content: '\eae3';
}
.icon-wl-note-close:before {
  content: '\eae4';
}
.icon-wl-note-download:before {
  content: '\eae5';
}
.icon-wl-note-minus:before {
  content: '\eae6';
}
.icon-wl-note-plus:before {
  content: '\eae7';
}
.icon-wl-note-question:before {
  content: '\eae8';
}
.icon-wl-note-search:before {
  content: '\eae9';
}
.icon-wl-note-upload:before {
  content: '\eaea';
}
.icon-wl-notificaiton-off:before {
  content: '\eaeb';
}
.icon-wl-notification:before {
  content: '\eaec';
}
.icon-wl-notification-active:before {
  content: '\eaed';
}
.icon-wl-notification-help:before {
  content: '\eaee';
}
.icon-wl-notification-info:before {
  content: '\eaef';
}
.icon-wl-notification-minus:before {
  content: '\eaf0';
}
.icon-wl-notification-plus:before {
  content: '\eaf1';
}
.icon-wl-notification-unread:before {
  content: '\eaf2';
}
.icon-wl-object-align-bottom:before {
  content: '\eaf3';
}
.icon-wl-object-align-horizontal-centers:before {
  content: '\eaf4';
}
.icon-wl-object-align-left:before {
  content: '\eaf5';
}
.icon-wl-object-align-right:before {
  content: '\eaf6';
}
.icon-wl-object-align-top:before {
  content: '\eaf7';
}
.icon-wl-object-align-vertical-centers:before {
  content: '\eaf8';
}
.icon-wl-object-distribute-horizontal-spacing:before {
  content: '\eaf9';
}
.icon-wl-object-distribute-vertical-spacing:before {
  content: '\eafa';
}
.icon-wl-organization:before {
  content: '\eafb';
}
.icon-wl-paint-pour:before {
  content: '\eafc';
}
.icon-wl-palette:before {
  content: '\eafd';
}
.icon-wl-paperclip:before {
  content: '\eafe';
}
.icon-wl-paperclip-alt:before {
  content: '\eaff';
}
.icon-wl-paragraph:before {
  content: '\eb00';
}
.icon-wl-paragraph-spacing:before {
  content: '\eb01';
}
.icon-wl-paragraph-wrap:before {
  content: '\eb02';
}
.icon-wl-pause:before {
  content: '\eb03';
}
.icon-wl-pause-circle:before {
  content: '\eb04';
}
.icon-wl-pause-square:before {
  content: '\eb05';
}
.icon-wl-payments:before {
  content: '\eb06';
}
.icon-wl-pen:before {
  content: '\eb07';
}
.icon-wl-pentagon:before {
  content: '\eb08';
}
.icon-wl-pen-tool:before {
  content: '\eb09';
}
.icon-wl-pen-tool-minus:before {
  content: '\eb0a';
}
.icon-wl-pen-tool-plus:before {
  content: '\eb0b';
}
.icon-wl-perspective:before {
  content: '\eb0c';
}
.icon-wl-perspective-alt:before {
  content: '\eb0d';
}
.icon-wl-phone:before {
  content: '\eb0e';
}
.icon-wl-phone-android:before {
  content: '\eb0f';
}
.icon-wl-phone-call:before {
  content: '\eb10';
}
.icon-wl-phone-disabled:before {
  content: '\eb11';
}
.icon-wl-phone-hang-up:before {
  content: '\eb12';
}
.icon-wl-phone-incoming:before {
  content: '\eb13';
}
.icon-wl-phone-ios:before {
  content: '\eb14';
}
.icon-wl-phone-outgoing:before {
  content: '\eb15';
}
.icon-wl-phone-pause:before {
  content: '\eb16';
}
.icon-wl-phone-plus:before {
  content: '\eb17';
}
.icon-wl-picture:before {
  content: '\eb18';
}
.icon-wl-picture-alt:before {
  content: '\eb19';
}
.icon-wl-picture-check:before {
  content: '\eb1a';
}
.icon-wl-picture-close:before {
  content: '\eb1b';
}
.icon-wl-picture-download:before {
  content: '\eb1c';
}
.icon-wl-picture-in-picture:before {
  content: '\eb1d';
}
.icon-wl-picture-minus:before {
  content: '\eb1e';
}
.icon-wl-picture-plus:before {
  content: '\eb1f';
}
.icon-wl-pictures:before {
  content: '\eb20';
}
.icon-wl-picture-upload:before {
  content: '\eb21';
}
.icon-wl-pie-chart:before {
  content: '\eb22';
}
.icon-wl-pie-chart-alt:before {
  content: '\eb23';
}
.icon-wl-pill:before {
  content: '\eb24';
}
.icon-wl-pin:before {
  content: '\eb25';
}
.icon-wl-play:before {
  content: '\eb26';
}
.icon-wl-play-circle:before {
  content: '\eb27';
}
.icon-wl-play-square:before {
  content: '\eb28';
}
.icon-wl-plus:before {
  content: '\eb29';
}
.icon-wl-plus-circle:before {
  content: '\eb2a';
}
.icon-wl-plus-square:before {
  content: '\eb2b';
}
.icon-wl-podcast:before {
  content: '\eb2c';
}
.icon-wl-polygon:before {
  content: '\eb2d';
}
.icon-wl-prescriptions:before {
  content: '\eb2e';
}
.icon-wl-printer:before {
  content: '\eb2f';
}
.icon-wl-privacy:before {
  content: '\eb30';
}
.icon-wl-purchase-tag:before {
  content: '\eb31';
}
.icon-wl-purchase-tag-alt:before {
  content: '\eb32';
}
.icon-wl-pyramid:before {
  content: '\eb33';
}
.icon-wl-qr-code:before {
  content: '\eb34';
}
.icon-wl-qr-code-alt:before {
  content: '\eb35';
}
.icon-wl-radio:before {
  content: '\eb36';
}
.icon-wl-receipt:before {
  content: '\eb37';
}
.icon-wl-receipt-alt:before {
  content: '\eb38';
}
.icon-wl-record:before {
  content: '\eb39';
}
.icon-wl-record-off:before {
  content: '\eb3a';
}
.icon-wl-record-plus:before {
  content: '\eb3b';
}
.icon-wl-rectangle-horizontal:before {
  content: '\eb3c';
}
.icon-wl-rectangle-vertical:before {
  content: '\eb3d';
}
.icon-wl-redo:before {
  content: '\eb3e';
}
.icon-wl-reflect-horizontal:before {
  content: '\eb3f';
}
.icon-wl-reflect-horizontal-alt:before {
  content: '\eb40';
}
.icon-wl-reflect-vertical:before {
  content: '\eb41';
}
.icon-wl-reflect-vertical-alt:before {
  content: '\eb42';
}
.icon-wl-refresh:before {
  content: '\eb43';
}
.icon-wl-refresh-alt:before {
  content: '\eb44';
}
.icon-wl-remove:before {
  content: '\eb45';
}
.icon-wl-repeat:before {
  content: '\eb46';
}
.icon-wl-report:before {
  content: '\eb47';
}
.icon-wl-right-indent:before {
  content: '\eb48';
}
.icon-wl-roller-bush:before {
  content: '\eb49';
}
.icon-wl-router:before {
  content: '\eb4a';
}
.icon-wl-router-alt:before {
  content: '\eb4b';
}
.icon-wl-routine:before {
  content: '\eb4c';
}
.icon-wl-row-view-double:before {
  content: '\eb4d';
}
.icon-wl-row-view-horizontal:before {
  content: '\eb4e';
}
.icon-wl-row-view-triple:before {
  content: '\eb4f';
}
.icon-wl-rss:before {
  content: '\eb50';
}
.icon-wl-ruble:before {
  content: '\eb51';
}
.icon-wl-ruble-circle:before {
  content: '\eb52';
}
.icon-wl-rupee:before {
  content: '\eb53';
}
.icon-wl-rupee-circle:before {
  content: '\eb54';
}
.icon-wl-save:before {
  content: '\eb55';
}
.icon-wl-scanner:before {
  content: '\eb56';
}
.icon-wl-school:before {
  content: '\eb57';
}
.icon-wl-school-bell:before {
  content: '\eb58';
}
.icon-wl-school-bell-off:before {
  content: '\eb59';
}
.icon-wl-search:before {
  content: '\eb5a';
}
.icon-wl-search-minus:before {
  content: '\eb5b';
}
.icon-wl-search-plus:before {
  content: '\eb5c';
}
.icon-wl-security:before {
  content: '\eb5d';
}
.icon-wl-selection-exclude:before {
  content: '\eb5e';
}
.icon-wl-selection-intersect:before {
  content: '\eb5f';
}
.icon-wl-selection-subtract:before {
  content: '\eb60';
}
.icon-wl-selection-subtract-alt:before {
  content: '\eb61';
}
.icon-wl-selection-union:before {
  content: '\eb62';
}
.icon-wl-selection-union-outline:before {
  content: '\eb63';
}
.icon-wl-send:before {
  content: '\eb64';
}
.icon-wl-send-alt:before {
  content: '\eb65';
}
.icon-wl-sensors:before {
  content: '\eb66';
}
.icon-wl-sensors-off:before {
  content: '\eb67';
}
.icon-wl-setting:before {
  content: '\eb68';
}
.icon-wl-shapes:before {
  content: '\eb69';
}
.icon-wl-share:before {
  content: '\eb6a';
}
.icon-wl-share-alt:before {
  content: '\eb6b';
}
.icon-wl-shield:before {
  content: '\eb6c';
}
.icon-wl-shield-1:before {
  content: '\eb6d';
}
.icon-wl-shield-alert:before {
  content: '\eb6e';
}
.icon-wl-shield-check:before {
  content: '\eb6f';
}
.icon-wl-shield-encrypted:before {
  content: '\eb70';
}
.icon-wl-shield-lock:before {
  content: '\eb71';
}
.icon-wl-shield-minus:before {
  content: '\eb72';
}
.icon-wl-shield-off:before {
  content: '\eb73';
}
.icon-wl-shield-plus:before {
  content: '\eb74';
}
.icon-wl-shield-question:before {
  content: '\eb75';
}
.icon-wl-shield-remove:before {
  content: '\eb76';
}
.icon-wl-shield-search:before {
  content: '\eb77';
}
.icon-wl-shield-user:before {
  content: '\eb78';
}
.icon-wl-shop:before {
  content: '\eb79';
}
.icon-wl-shop-alt:before {
  content: '\eb7a';
}
.icon-wl-shop-off:before {
  content: '\eb7b';
}
.icon-wl-shopping-bag:before {
  content: '\eb7c';
}
.icon-wl-shuffle:before {
  content: '\eb7d';
}
.icon-wl-shuffle-alt:before {
  content: '\eb7e';
}
.icon-wl-signal:before {
  content: '\eb7f';
}
.icon-wl-signal-good:before {
  content: '\eb80';
}
.icon-wl-signal-low:before {
  content: '\eb81';
}
.icon-wl-signal-mid:before {
  content: '\eb82';
}
.icon-wl-signal-off:before {
  content: '\eb83';
}
.icon-wl-simcard:before {
  content: '\eb84';
}
.icon-wl-skew:before {
  content: '\eb85';
}
.icon-wl-skip-backward:before {
  content: '\eb86';
}
.icon-wl-skip-forward:before {
  content: '\eb87';
}
.icon-wl-snow:before {
  content: '\eb88';
}
.icon-wl-snowflake:before {
  content: '\eb89';
}
.icon-wl-solid-close-circle:before {
  content: '\eb8a';
}
.icon-wl-solid-record-plus:before {
  content: '\eb8b';
}
.icon-wl-sort-alphabet-ascending:before {
  content: '\eb8c';
}
.icon-wl-sort-alphabet-descending:before {
  content: '\eb8d';
}
.icon-wl-sort-ascending:before {
  content: '\eb8e';
}
.icon-wl-sort-descending:before {
  content: '\eb8f';
}
.icon-wl-speaker:before {
  content: '\eb90';
}
.icon-wl-speakerphone:before {
  content: '\eb91';
}
.icon-wl-spellcheck:before {
  content: '\eb92';
}
.icon-wl-square:before {
  content: '\eb93';
}
.icon-wl-stacks:before {
  content: '\eb94';
}
.icon-wl-star:before {
  content: '\eb95';
}
.icon-wl-star-alt:before {
  content: '\eb96';
}
.icon-wl-stars:before {
  content: '\eb97';
}
.icon-wl-stars-alt:before {
  content: '\eb98';
}
.icon-wl-stop:before {
  content: '\eb99';
}
.icon-wl-stop-circle:before {
  content: '\eb9a';
}
.icon-wl-stopwatch:before {
  content: '\eb9b';
}
.icon-wl-strikethrough:before {
  content: '\eb9c';
}
.icon-wl-subscript:before {
  content: '\eb9d';
}
.icon-wl-sun:before {
  content: '\eb9e';
}
.icon-wl-sun-cloud:before {
  content: '\eb9f';
}
.icon-wl-sunset:before {
  content: '\eba0';
}
.icon-wl-superscript:before {
  content: '\eba1';
}
.icon-wl-swap-horizontal:before {
  content: '\eba2';
}
.icon-wl-swap-vertical:before {
  content: '\eba3';
}
.icon-wl-synagogue:before {
  content: '\eba4';
}
.icon-wl-sync:before {
  content: '\eba5';
}
.icon-wl-sync-alt:before {
  content: '\eba6';
}
.icon-wl-syringe:before {
  content: '\eba7';
}
.icon-wl-table-2x2:before {
  content: '\eba8';
}
.icon-wl-table-2x3:before {
  content: '\eba9';
}
.icon-wl-table-3x2:before {
  content: '\ebaa';
}
.icon-wl-table-3x3:before {
  content: '\ebab';
}
.icon-wl-tablet-horizontal:before {
  content: '\ebac';
}
.icon-wl-tablets:before {
  content: '\ebad';
}
.icon-wl-tablet-vertical:before {
  content: '\ebae';
}
.icon-wl-target:before {
  content: '\ebaf';
}
.icon-wl-temple-alt:before {
  content: '\ebb0';
}
.icon-wl-temple-Buddhist:before {
  content: '\ebb1';
}
.icon-wl-text:before {
  content: '\ebb2';
}
.icon-wl-text-decrease:before {
  content: '\ebb3';
}
.icon-wl-text-format:before {
  content: '\ebb4';
}
.icon-wl-text-increase:before {
  content: '\ebb5';
}
.icon-wl-thermometer:before {
  content: '\ebb6';
}
.icon-wl-thermometer-alt:before {
  content: '\ebb7';
}
.icon-wl-thermometer-minus:before {
  content: '\ebb8';
}
.icon-wl-thermometer-plus:before {
  content: '\ebb9';
}
.icon-wl-tidy-up:before {
  content: '\ebba';
}
.icon-wl-tidy-up-dots:before {
  content: '\ebbb';
}
.icon-wl-tool:before {
  content: '\ebbc';
}
.icon-wl-transform:before {
  content: '\ebbd';
}
.icon-wl-trending-down:before {
  content: '\ebbe';
}
.icon-wl-trending-up:before {
  content: '\ebbf';
}
.icon-wl-triangle:before {
  content: '\ebc0';
}
.icon-wl-tsunami:before {
  content: '\ebc1';
}
.icon-wl-tv:before {
  content: '\ebc2';
}
.icon-wl-underline:before {
  content: '\ebc3';
}
.icon-wl-undo:before {
  content: '\ebc4';
}
.icon-wl-unfold-horizontal:before {
  content: '\ebc5';
}
.icon-wl-unfold-vertical:before {
  content: '\ebc6';
}
.icon-wl-unlink:before {
  content: '\ebc7';
}
.icon-wl-unpin:before {
  content: '\ebc8';
}
.icon-wl-upload:before {
  content: '\ebc9';
}
.icon-wl-upload-cloud:before {
  content: '\ebca';
}
.icon-wl-usb:before {
  content: '\ebcb';
}
.icon-wl-user:before {
  content: '\ebcc';
}
.icon-wl-user-check:before {
  content: '\ebcd';
}
.icon-wl-user-circle:before {
  content: '\ebce';
}
.icon-wl-user-close:before {
  content: '\ebcf';
}
.icon-wl-user-down:before {
  content: '\ebd0';
}
.icon-wl-user-key:before {
  content: '\ebd1';
}
.icon-wl-user-left:before {
  content: '\ebd2';
}
.icon-wl-user-lock:before {
  content: '\ebd3';
}
.icon-wl-user-minus:before {
  content: '\ebd4';
}
.icon-wl-user-plus:before {
  content: '\ebd5';
}
.icon-wl-user-right:before {
  content: '\ebd6';
}
.icon-wl-users:before {
  content: '\ebd7';
}
.icon-wl-users-check:before {
  content: '\ebd8';
}
.icon-wl-users-close:before {
  content: '\ebd9';
}
.icon-wl-users-down:before {
  content: '\ebda';
}
.icon-wl-users-left:before {
  content: '\ebdb';
}
.icon-wl-users-minus:before {
  content: '\ebdc';
}
.icon-wl-users-plus:before {
  content: '\ebdd';
}
.icon-wl-user-square:before {
  content: '\ebde';
}
.icon-wl-users-right:before {
  content: '\ebdf';
}
.icon-wl-users-up:before {
  content: '\ebe0';
}
.icon-wl-user-up:before {
  content: '\ebe1';
}
.icon-wl-verify:before {
  content: '\ebe2';
}
.icon-wl-vertical-bar-chart:before {
  content: '\ebe3';
}
.icon-wl-vertical-bar-chart-block:before {
  content: '\ebe4';
}
.icon-wl-vertical-bar-chart-block-decrease:before {
  content: '\ebe5';
}
.icon-wl-vertical-bar-chart-block-increase:before {
  content: '\ebe6';
}
.icon-wl-vertical-bar-chart-block-line:before {
  content: '\ebe7';
}
.icon-wl-vertical-bar-chart-block-line-decrease:before {
  content: '\ebe8';
}
.icon-wl-vertical-bar-chart-block-line-increase:before {
  content: '\ebe9';
}
.icon-wl-vertical-bar-chart-circle:before {
  content: '\ebea';
}
.icon-wl-vertical-bar-chart-circle-decrease:before {
  content: '\ebeb';
}
.icon-wl-vertical-bar-chart-circle-increase:before {
  content: '\ebec';
}
.icon-wl-vertical-bar-chart-decrease:before {
  content: '\ebed';
}
.icon-wl-vertical-bar-chart-increase:before {
  content: '\ebee';
}
.icon-wl-vertical-bar-chart-line:before {
  content: '\ebef';
}
.icon-wl-vertical-bar-chart-line-decrease:before {
  content: '\ebf0';
}
.icon-wl-vertical-bar-chart-line-increase:before {
  content: '\ebf1';
}
.icon-wl-vertical-bar-chart-square:before {
  content: '\ebf2';
}
.icon-wl-vertical-bar-chart-square-decrease:before {
  content: '\ebf3';
}
.icon-wl-vertical-bar-chart-square-increase:before {
  content: '\ebf4';
}
.icon-wl-voicemail:before {
  content: '\ebf5';
}
.icon-wl-voice-record:before {
  content: '\ebf6';
}
.icon-wl-voice-record-circle:before {
  content: '\ebf7';
}
.icon-wl-volume:before {
  content: '\ebf8';
}
.icon-wl-volume-close:before {
  content: '\ebf9';
}
.icon-wl-volume-low:before {
  content: '\ebfa';
}
.icon-wl-volume-minus:before {
  content: '\ebfb';
}
.icon-wl-volume-plus:before {
  content: '\ebfc';
}
.icon-wl-wallet:before {
  content: '\ebfd';
}
.icon-wl-wallet-alt:before {
  content: '\ebfe';
}
.icon-wl-warning:before {
  content: '\ebff';
}
.icon-wl-watch-circle:before {
  content: '\ec00';
}
.icon-wl-watch-square:before {
  content: '\ec01';
}
.icon-wl-wave:before {
  content: '\ec02';
}
.icon-wl-webcam:before {
  content: '\ec03';
}
.icon-wl-webcam-off:before {
  content: '\ec04';
}
.icon-wl-wifi:before {
  content: '\ec05';
}
.icon-wl-wifi-good:before {
  content: '\ec06';
}
.icon-wl-wifi-lock:before {
  content: '\ec07';
}
.icon-wl-wifi-low:before {
  content: '\ec08';
}
.icon-wl-wifi-mid:before {
  content: '\ec09';
}
.icon-wl-wifi-off:before {
  content: '\ec0a';
}
.icon-wl-wind:before {
  content: '\ec0b';
}
.icon-wl-won:before {
  content: '\ec0c';
}
.icon-wl-won-circle:before {
  content: '\ec0d';
}
.icon-wl-yen:before {
  content: '\ec0e';
}
.icon-wl-yen-circle:before {
  content: '\ec0f';
}
