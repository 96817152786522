@font-face {
  font-family: 'Inter';
  src:
    local(''),
    url('/assets/fonts/Inter/static/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Inter';
  src:
    local(''),
    url('/assets/fonts/Inter/static/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Inter';
  src:
    local(''),
    url('/assets/fonts/Inter/static/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

%font-default {
  font-family: 'Inter', sans-serif;
}
* {
  @extend %font-default;
}

::-webkit-input-placeholder {
  @extend %font-default;
}

:-ms-input-placeholder {
  @extend %font-default;
}

:-moz-placeholder {
  @extend %font-default;
}

::-moz-placeholder {
  @extend %font-default;
}
